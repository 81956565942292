<template>
  <div style="padding-bottom: 30px;">
    <div class="plan-wrap">
      <div class="plan-list">
        <div class="plan-item">
          <div class="plan-details">
            <div
              class="logo-box pointer"
              @click="toProjectDetails(projectDetails.id)"
            >
              <img
                class="brandLogo"
                :src="projectDetails.projectLogo + '?w=200&h=200'"
                alt=""
              >
              <span>查看项目详情</span>
            </div>
            <div class="details">
              <div class="select-wrap">
                <el-select
                  :style="{width: inputNumber + '%'}"
                  v-if="!selectShow"
                  @change="changeProject"
                  popper-class="w-block-select-down"
                  v-model="projectName"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  :style="{width: planNumber + 'px'}"
                  v-model="planName"
                  @change="changePlan"
                  popper-class="w-block-select-down"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in taskList"
                    :key="item.value"
                    :label="item.label"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="label">
                <span><i class="icon-shop"></i> {{projectHeadData.projectType}}</span>
                <span><i class="icon-level"></i> {{projectHeadData.projectLevel}}</span>
              </div> -->
              <div
                class="parameter"
                style="margin-top: 16px;"
              >
                <span>项目类型：<i>{{projectDetails.projectType}}</i> </span>
                <span v-if="projectDetails.projectLevel">项目档次：<i>{{projectDetails.projectLevel}}</i> </span>
              </div>
              <div class="parameter">
                <span>商业面积：<i>{{projectDetails.projectArea}} ㎡</i> </span>
                <span>商业楼层：<i>{{projectDetails.projectFloor}}</i> </span>
                <span>商铺数量：<i>{{projectDetails.berthNum}}</i> </span>
              </div>
              <div class="parameter">
                <span>品牌数量：<i>{{brandData.locationNum}}</i> </span>
                <span>连锁品牌：<i>{{brandData.isliansuo}}</i> </span>
                <span>新兴品牌：<i>{{brandData.isXxPp}}</i> </span>
              </div>
            </div>
          </div>
          <div class="pk-text">
            <img
              class="pkimg"
              src="@/assets/images/workBench/PK1.png"
              alt=""
            >
            <p class="pone">重合品牌数量</p>
            <p class="ptwo">{{coincidenceNumber}}</p>
          </div>
        </div>
        <div class="plan-item">
          <div class="plan-details">
            <div
              class="logo-box pointer"
              @click="toProjectDetails(defaultProDetails.id)"
            >
              <img
                class="brandLogo"
                :src="defaultProDetails.projectLogo + '?w=200&h=200'"
                alt=""
              >
              <span>查看项目详情</span>
            </div>
            <div class="details">
              <div class="select-wrap contraet-input-wrap">
                <i class="el-icon-search"></i>
                <input
                  v-model="compName"
                  @input="inputBlur"
                  @blur="inputBlur"
                  placeholder="输入项目关键字并选择匹配到的结果"
                  type="text"
                >
                <div
                  class="search-list"
                  v-show="compProjectList && compProjectList.length > 0"
                >
                  <ul>
                    <li
                      v-for="(item, index) in compProjectList"
                      :key="index"
                      @click="selectProject(item)"
                    >{{item.projectName}}</li>
                    <li v-show="compProjectList.length === 0">没有匹配的项目存在</li>
                  </ul>
                </div>
                <div
                  class="search-list"
                  v-show="showLoading"
                >
                  <ul>
                    <li>没有匹配的项目存在</li>
                  </ul>
                </div>
              </div>
              <!-- <div class="label">
                <span><i class="icon-shop"></i> {{defaultProDetails.projectType}}</span>
                <span v-if="defaultProDetails.projectLevel"><i class="icon-level"></i> {{defaultProDetails.projectLevel}}</span>
              </div> -->
              <div
                class="parameter"
                style="margin-top: 16px;"
              >
                <span>项目类型：<i>{{defaultProDetails.projectType}} </i> </span>
                <span v-if="defaultProDetails.projectLevel">项目档次：<i>{{defaultProDetails.projectLevel}}</i> </span>
              </div>
              <div class="parameter">
                <span>商业面积：<i>{{defaultProDetails.projectArea}} ㎡</i> </span>
                <span>商业楼层：<i>{{defaultProDetails.projectFloor}}</i> </span>
                <span>商铺数量：<i>{{defaultProDetails.berthNum ? defaultProDetails.berthNum: '-'}}</i> </span>
              </div>
              <div class="parameter">
                <span>品牌数量：<i>{{defaultProDetails.brandCount}}</i> </span>
                <span>连锁品牌：<i>{{defaultProDetails.isLiansuo}}</i> </span>
                <span>新兴品牌：<i>{{defaultProDetails.isXxPp}}</i> </span>
              </div>
            </div>
          </div>
          <img
            class="pkimg"
            src="@/assets/images/workBench/pk.png"
            alt=""
          >
        </div>
      </div>
      <div class="area-contrast">
        <i style="top: 3px;"></i>业态面积占比对比
        <el-tooltip
          class="item"
          popper-class="planTooltip"
          effect="dark"
          placement="bottom"
        >
          <em>
            <span class="icon-mark"></span>
          </em>
          <div slot="content">一个铺位多个落位计划品牌时，以首选落位品牌为基准进行分析</div>
        </el-tooltip>
        <div class="floor-item">
          <el-select
            v-model="selectType"
            @change="changeType"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ytList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="charts-wrap">
        <div
          id="planBar"
          :style="{width:'1280px',height:'320px'}"
        ></div>
        <div class="legend-title">
          <i></i>
          <span>{{projectDetails.projectName}}</span>
          <i class="planColor"></i>
          <span>{{slectCompName}}</span>
        </div>
      </div>
      <div class="area-contrast">
        <i></i>
        <div class="select-wrap">
          <el-select
            @change="changeTitle2"
            v-model="titleVal2"
            popper-class="w-block-select-down"
          >
            <el-option
              v-for="item in titleList2"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-tooltip
            class="item"
            popper-class="planTooltip"
            effect="dark"
            placement="bottom"
          >
            <em>
              <span class="icon-mark"></span>
            </em>
            <div slot="content">一个铺位多个落位计划品牌时，以首选落位品牌为基准进行分析</div>
          </el-tooltip>
        </div>
        <div class="floor-item">
          <el-select
            v-model="selectType2"
            @change="changeType2"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ytList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="charts-wrap">
        <div
          id="planBar2"
          :style="{width:'1280px',height:'320px'}"
        ></div>
        <div class="legend-title">
          <i></i>
          <span>{{projectDetails.projectName}}</span>
          <i class="planColor"></i>
          <span>{{slectCompName}}</span>
        </div>
      </div>
      <div class="area-contrast">
        <i style="top:2px;"></i>品牌组合对比
      </div>
      <div class="type-wrap">
        <span
          v-for="(item, index) in typeList"
          :key="index"
          :class="[item === typeName ? 'active': '']"
          @click="tabClick(item)"
        >{{item}}</span>
      </div>
      <div
        class="two-type-wrap"
        v-if="twoTypeList && twoTypeList.length > 0"
      >
        <span
          v-for="(item, index) in twoTypeList"
          :key="index"
          :class="[twoTypeName === item ? 'active': '']"
          @click="tabTwoClick(item)"
        >{{item}}</span>
      </div>
      <div
        class="brand-wrap"
        v-if="brandList.list && brandList.list.length > 0"
      >
        <div class="brand-item">
          <div class="title">{{brandList.list[0].planName}}</div>
          <div
            class="pointer"
            @click="toDetails(list)"
            v-for="(list, key) in brandList.list[0].brandNames"
            :key="key"
            :class="[list.sfSame?'same':'']"
          >{{list.brandName}}</div>
        </div>
        <div class="coincidence-brand">
          <p class="p1">重合品牌数量</p>
          <p class="p2">{{brandList.coincide}}</p>
        </div>
        <div class="brand-item">
          <div class="title">{{brandList.list[1].planName}}</div>
          <div
            class="pointer"
            @click="toDetails2(list)"
            v-for="(list, key) in brandList.list[1].brandNames"
            :key="key"
            :class="[list.sfSame?'same':'']"
          >{{list.brandName}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  data () {
    return {
      projectList: [],
      projectName: '',
      compName: '',
      floorStr: '全部楼层',
      floorArr: [],
      typeList: [],
      twoTypeList: [],
      typeName: '',
      twoTypeName: '不限',
      brandList: [],
      selectShow: false,
      projectHeadData: {},
      selectProjectId: '',
      defaultProDetails: {},
      searchHide: false,
      compProjectList: [],
      taskList: [],
      planName: '',
      planId: '',
      slectCompName: '',
      selectType: '一级业态',
      selectType2: '一级业态',
      selectTypeId: '1',
      selectTypeId2: '1',
      ytList: [{ label: '一级业态', value: '1' }, { label: '二级业态', value: '2' }],
      ytList2: [{ label: '一级业态', value: '1' }, { label: '二级业态', value: '2' }],
      inputNumber: 60,
      planNumber: 150,
      projectDetails: {},
      showLoading: false,
      titleVal2: '新兴品牌数量对比',
      titleId: 1,
      titleList2: [{ label: '新兴品牌数量对比', value: 1 }, { label: '连锁品牌数量对比', value: 2 }],
      coincidenceNumber: 0,
      brandData: {},
      brandDataList: []
    }
  },
  watch: {
    compName (newVal, oldVal) {
      if (newVal === oldVal) {
        this.compProjectList = []
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  mounted () {
    this.getBerthProjectFunc()
  },
  methods: {
    toProjectDetails (id) {
      const projectDetailsHref = this.$router.resolve({
        path: '/projectDetail',
        query: {
          id: id,
          source: 1
        }
      })
      window.open('/' + projectDetailsHref.href, '_blank')
    },
    changeTitle2 (item) {
      this.titleId = item.value
      this.getContrastType2(this.defaultProDetails.id, this.selectProjectId, this.planId)
    },
    changeType (val) {
      this.selectTypeId = val
      this.getContrastType(this.defaultProDetails.id, this.selectProjectId, this.planId)
    },
    changeType2 (val) {
      this.selectTypeId2 = val
      this.getContrastType2(this.defaultProDetails.id, this.selectProjectId, this.planId)
    },
    getBerthProjectFunc () {
      this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.projectName
            obj.value = item.id
            return obj
          })
          this.projectName = this.projectList[0].label
          // this.inputNumber = this.byteLength(this.projectName) * 14
          this.selectProjectId = this.projectList[0].value
          this.projectDetails = this.projectList[0]
          this.$nextTick(() => {
            this.selectShow = false
          })
          // 获取头部项目数据
          this.getProjectData()
          // this.getTsakFunc()
          this.getBrandNumber()
        })
    },
    getBrandNumber () {
      this.axios.post(api.getSchemeComparisonBrandCountPie, { projectId: this.selectProjectId })
        .then((res) => {
          this.brandDataList = res.data.data
          this.brandData = res.data.data[0]
        })
    },
    getTsakFunc () {
      this.axios.post(api.getPlanList, { projectId: this.selectProjectId })
        .then((res) => {
          this.taskList = res.data.data.map((item) => {
            const obj = {
              label: item.planName,
              value: item.id
            }
            return obj
          })
          this.planName = this.taskList[0].label
          // this.planNumber = this.byteLength(this.planName) * 15
          this.planId = this.taskList[0].value
          this.getDefaultProjectFunc()
        })
    },
    getProjectData () {
      this.axios.post(api.getProjectEntiretyData, { projectId: this.selectProjectId })
        .then((res) => {
          this.projectHeadData = res.data.data
        })
    },
    changeProject (value) {
      if (typeof (value) !== 'string') {
        this.projectList = JSON.parse((JSON.stringify(this.projectList)))
        for (let i = 0; i < this.projectList.length; i++) {
          if (this.projectList[i].value === value) {
            this.projectDetails.id = this.projectList[i].id
            this.projectDetails.label = this.projectList[i].label
            this.projectDetails.projectArea = this.projectList[i].projectArea
            this.projectDetails.projectFloor = this.projectList[i].projectFloor
            this.projectDetails.projectLevel = this.projectList[i].projectLevel
            this.projectDetails.projectName = this.projectList[i].projectName
            this.projectDetails.projectType = this.projectList[i].projectType
            this.projectDetails.rentYear = this.projectList[i].rentYear
            this.projectDetails.value = this.projectList[i].value
            this.projectDetails.projectLogo = this.projectList[i].projectLogo
            this.projectDetails.berthNum = this.projectList[i].berthNum
          }
        }
        this.selectTypeId = '1'
        this.selectProjectId = value
        // this.inputNumber = this.byteLength(this.projectDetails.projectName) * 14
        // this.getTsakFunc()
        this.getTypeFunc()
        this.getBrandNumber()
        this.brandList = []
        this.twoTypeList = []
        this.typeName = ''
        this.twoTypeName = '不限'
      }
    },
    changePlan (item) {
      if (typeof (item) !== 'string') {
        this.planId = item.value
        this.selectTypeId = '1'
        this.getContrastType(this.defaultProDetails.id, this.selectProjectId, this.planId)
        this.getTypeFunc()
        this.brandList = []
        this.twoTypeList = []
        this.typeName = ''
        this.twoTypeName = '不限'
        for (let i = 0; i < this.brandDataList.length; i++) {
          if (this.brandDataList[i].name === item.label) {
            this.brandData = this.brandDataList[i]
          }
        }
      }
    },
    selectProject (item) {
      this.defaultProDetails = item
      this.compName = this.defaultProDetails.projectName
      this.slectCompName = this.defaultProDetails.projectName
      this.selectTypeId = '1'
      this.compProjectList = []
      this.getContrastType(this.defaultProDetails.id, this.selectProjectId, this.planId)
      this.getContrastType2(this.defaultProDetails.id, this.selectProjectId, this.planId)
      this.getCoincidenceBrandNumber(this.defaultProDetails.id, this.selectProjectId, this.planId)
      this.getTypeFunc()
      this.brandList = []
      this.twoTypeList = []
      this.compProjectList = []
      this.typeName = ''
      this.twoTypeName = '不限'
      if (this.compName) {
        this.searchHide = true
      }
    },
    getDefaultProjectFunc () {
      this.axios.post(api.defaultProjectInfo)
        .then((res) => {
          this.defaultProDetails = res.data.data
          this.compName = this.defaultProDetails.projectName
          this.slectCompName = this.defaultProDetails.projectName
          this.getContrastType(this.defaultProDetails.id, this.selectProjectId, this.planId)
          this.getContrastType2(this.defaultProDetails.id, this.selectProjectId, this.planId)
          this.getCoincidenceBrandNumber(this.defaultProDetails.id, this.selectProjectId, this.planId)
          this.getTypeFunc()
        })
    },
    getCoincidenceBrandNumber (comId, id, planId) {
      this.axios.post(api.coincidenceBrandNumber,
        {
          comProjectId: comId,
          projectId: id,
          planId: planId,
          type: this.selectTypeId
        })
        .then((res) => {
          this.coincidenceNumber = res.data.data
        })
    },
    inputBlur () {
      if (this.compName) {
        this.searchHide = true
        this.axios.post(api.inputValSearchProject, { projectName: this.compName })
          .then((res) => {
            this.compProjectList = res.data.data
            this.searchHide = false
            if (this.compProjectList.length === 0) {
              this.showLoading = true
            } else {
              this.showLoading = false
            }
            if (this.compProjectList.length === 1) {
              // this.selectProject(this.compProjectList[0])
            }
          })
      } else {
        this.showLoading = false
        this.searchHide = false
        this.compProjectList = []
      }
    },
    getContrastType (comId, id, planId) {
      this.axios.post(api.getComparisonProjectArea,
        {
          comProjectId: comId,
          projectId: id,
          planId: planId,
          type: this.selectTypeId
        })
        .then((res) => {
          this.setBarData(res.data.data)
        })
    },
    getContrastType2 (comId, id, planId) {
      this.axios.post(api.getComparisonProjectNumber,
        {
          comProjectId: comId,
          projectId: id,
          planId: planId,
          type: this.selectTypeId2,
          selectType: this.titleId
        })
        .then((res) => {
          this.setBarData2(res.data.data)
        })
    },
    getTypeFunc () {
      this.axios.post(api.getProjectContrastTypeList,
        {
          comProjectId: this.defaultProDetails.id,
          planId: this.planId,
          projectId: this.selectProjectId
        })
        .then((res) => {
          this.typeList = res.data.data
          this.typeName = this.typeList[0]
          this.getTwoTypeFunc(this.typeName)
        })
    },
    tabClick (item) {
      this.typeName = item
      this.twoTypeName = '不限'
      this.getTwoTypeFunc(item)
      this.getBrandInfo()
    },
    tabTwoClick (item) {
      this.twoTypeName = item
      this.getBrandInfo()
    },
    getBrandInfo () {
      this.axios.post(api.getProjectContrastBrandList,
        {
          typeName1: this.typeName,
          typeName2: this.twoTypeName,
          comProjectId: this.defaultProDetails.id,
          planId: this.planId,
          projectId: this.selectProjectId
        })
        .then((res) => {
          this.brandList = res.data.data
        })
    },
    getTwoTypeFunc (typeName) {
      this.axios.post(api.getProjectContrastTypeList,
        {
          comProjectId: this.defaultProDetails.id,
          planId: this.planId,
          projectId: this.selectProjectId,
          yetaiName: typeName
        })
        .then((res) => {
          this.twoTypeName = '不限'
          this.twoTypeList = res.data.data
          this.getBrandInfo()
        })
    },
    toDetails (item) {
      // if (item.brandId > 0) {
      //   const brandDetailsHref = this.$router.resolve({
      //     path: '/brandDetail',
      //     query: {
      //       id: item.brandId,
      //       source: 1,
      //       gbid: item.gbid,
      //       questionState: 0
      //     }
      //   })
      //   window.open('/' + brandDetailsHref.href, '_blank')
      // } else {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
      // }
    },
    toDetails2 (item) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: item.brandId,
          source: 1,
          gbid: item.gbid,
          questionState: 0
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    },
    setBarData (data) {
      const chat = []
      for (let i = 0; i < data.data.length; i++) {
        const obj = {}
        obj.name = data.data[i].name
        obj.barGap = 0.3
        obj.type = 'bar'
        obj.barWidth = '16px'
        obj.data = data.data[i].percent
        chat.push(obj)
      }
      const myChart = echarts.init(document.getElementById('planBar'))
      const option = {
        legend: {
          show: false,
          textStyle: {
            color: '#fff'
          },
          icon: 'square',
          right: 'center',
          bottom: '-8'
        },
        tooltip: {
          formatter (params) {
            for (const i in data.data) {
              if (params.seriesName === data.data[i].name) {
                return params.seriesName + '<br/>' + params.name + '<br/>' + '面积：' + data.data[i].area[params.dataIndex] + '㎡' + '<br/>' + '占比：' + data.data[i].percent[params.dataIndex] + '%'
              }
            }
          }
        },
        color: ['#16DA64', '#26CBE9', '#3494FF', '#F78121'],
        // dataset: {
        //   source: [
        //     ['product', '2015', '2016', '2017'],
        //     ['Matcha Latte', 43.3, 85.8, 93.7],
        //     ['Milk Tea', 83.1, 73.4, 55.1],
        //     ['Cheese Cocoa', 86.4, 65.2, 82.5],
        //     ['Walnut Brownie', 72.4, 53.9, 39.1]
        //   ]
        // },
        xAxis: [
          {
            type: 'category',
            data: data.yetais,
            axisLabel: {
              rotate: 40
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            }
          }
        ],
        yAxis: [
          {
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            },
            axisLabel: {
              show: true,
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }
        ],
        series: chat
      }
      myChart.setOption(option)
    },
    setBarData2 (data) {
      const that = this
      const chat = []
      for (let i = 0; i < data.data.length; i++) {
        const obj = {}
        obj.name = data.data[i].name
        obj.barGap = 0.3
        obj.type = 'bar'
        obj.barWidth = '16px'
        obj.data = data.data[i].area
        chat.push(obj)
      }
      const myChart = echarts.init(document.getElementById('planBar2'))
      const option = {
        legend: {
          show: false,
          textStyle: {
            color: '#fff'
          },
          icon: 'square',
          right: 'center',
          bottom: '-8'
        },
        tooltip: {
          formatter (params) {
            for (const i in data.data) {
              if (params.seriesName === data.data[i].name) {
                return params.seriesName + '<br/>' + params.name + '<br/>' + (that.titleId === 1 ? '新兴品牌数量' : '连锁品牌数量') + '：' + data.data[i].area[params.dataIndex] + '<br/>' + '占比：' + data.data[i].percent[params.dataIndex] + '%'
              }
            }
          }
        },
        color: ['#16DA64', '#26CBE9', '#3494FF', '#F78121'],
        // dataset: {
        //   source: [
        //     ['product', '2015', '2016', '2017'],
        //     ['Matcha Latte', 43.3, 85.8, 93.7],
        //     ['Milk Tea', 83.1, 73.4, 55.1],
        //     ['Cheese Cocoa', 86.4, 65.2, 82.5],
        //     ['Walnut Brownie', 72.4, 53.9, 39.1]
        //   ]
        // },
        xAxis: [
          {
            type: 'category',
            data: data.yetais,
            axisLabel: {
              rotate: 40
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            }
          }
        ],
        yAxis: [
          {
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            },
            axisLabel: {
              show: true,
              formatter: '{value}'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }
        ],
        series: chat
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="stylus" scoped>
.plan-wrap
  width 1280px
  min-height 600px
  margin 0px auto
  position relative
  background #272930
  padding 24px 16px
  box-sizing border-box
  padding-bottom 30px
  .plan-list
    border 1px solid #FFA134
    box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
    padding 35px 30px 35px 30px
    display flex
    .plan-item
      display flex
      .pk-text
        text-align center
        .pkimg
          width 98px
          height 56px
          margin-left 20px
          margin-right 30px
        .pone
          font-size 12px
          color #fff
          margin-top 10px
          margin-bottom 5px
        .ptwo
          font-size 24px
          color #fff
          font-weight 500
      .plan-details
        h3
          font-weight 500
          color #fff
          font-size 22px
          margin-bottom 18px
        &>div
          color #999
          font-size 14px
          span
            color #ffffff
        .parameter
          line-height 21px
      &:last-child .pkimg
        display none
.area-contrast
  position relative
  margin-top 40px
  font-size 18px
  color #ffffff
  // border-left 2px solid #ffa134
  padding-left 10px
  margin-left 20px
  .floor-item
    position absolute
    right 0
    top 0
    input
      border-color #ffa134
  i
    position absolute
    left 0
    top 10.5px
    width 2px
    height 18px
    background #FFA134
.charts-wrap
  margin-top 30px
  border-top 1px solid rgba(226, 226, 226, 0.1)
  #planBar
    margin auto
.type-wrap
  margin-left 30px
  margin-top 36px
  span
    display inline-block
    width 138px
    height 34px
    line-height 34px
    text-align center
    border-radius 3px
    border 1px solid rgba(255, 255, 255, 0.5)
    color #fff
    font-size 16px
    margin-right 16px
    margin-bottom 10px
    cursor pointer
    &.active
      color #ffa134
      border-color #ffa134
.two-type-wrap
  background rgba(30, 30, 35, 0.5)
  height 45px
  line-height 45px
  color #fff
  margin-top 12px
  margin-left 30px
  padding-left 20px
  span
    margin-right 30px
    cursor pointer
    font-weight 500
    &.active
      color #ffa134
.brand-wrap
  display flex
  justify-content center
  .brand-item
    width 400px
    background rgba(226, 226, 226, 0.1)
    margin 0 30px
    color #fff
    box-sizing border-box
    margin-top 50px
    font-size 14px
    .title
      color rgba(255, 255, 255, 0.5)
    &>div
      height 44px
      line-height 44px
      padding-left 16px
      border-bottom 1px solid rgba(226, 226, 226, 0.1)
.plan-details
  position relative
  background #272930
  display flex
  color #fff
  align-items center
  .brandLogo
    width 114px
    height 114px
    border-radius 2px
  .details
    margin-left 20px
    .parameter
      font-size 12px
      width 400px
      span
        display inline-block
        width 130px
        // margin-right 20px
        &:last-child
          margin-right 0
        i
          font-weight bold
    .label
      margin 16px 8px 16px 0
      span
        display inline-block
        width 68px
        height 22px
        line-height 18px
        border-radius 22px
        font-size 12px
        padding-left 10px
        margin-right 6px
        background linear-gradient(210deg, rgba(213, 167, 119, 1) 1%, rgba(204, 145, 90, 1) 100%)
  .select-wrap
    display flex
.contraet-input-wrap
  position relative
  input
    width 280px
    height 32px
    line-height 32px
    background none
    border 1px solid rgba(255, 255, 255, 0.2)
    color #ffffff
    border-radius 3px
    padding-left 30px
  .el-icon-search
    position absolute
    left 10px
    top 10px
.search-list
  position absolute
  left 0
  top 34px
  background #272930
  width 280px
  box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
  padding 15px
  z-index 111
  ul li
    color #fff
    height 32px
    line-height 32px
    cursor pointer
    &:hover
      color #FFA134
    i
      color #FFA134
      float right
      margin-top 8px
.legend-title
  text-align center
  color #fff
  font-size 13px
  i
    display inline-block
    width 10px
    height 10px
    background #16DA64
    margin-right 5px
  .planColor
    background #26CBE9
    margin-left 20px
    margin-right 5px
.coincidence-brand
  margin-top 200px
  width 114px
  height 110px
  background linear-gradient(rgba(255, 161, 52, 0.25), rgba(0, 0, 0, 0.001))
  text-align center
  .p1
    font-size 12px
    color #fff
    font-weight 400
    margin-top 35px
    margin-bottom 5px
  .p2
    font-size 24px
    color #fff
    font-weight 500
.same
  color #ffdd55
.logo-box
  width 114px
  height 114px
  position relative
  span
    position absolute
    right 0
    bottom 0
    color #fff
    background rgba(92, 70, 49, 0.8)
    border-radius 8px 0 2px 0
    font-size 12px
    padding 0 4px
</style>
