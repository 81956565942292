<template>
  <div style="padding-bottom: 30px;">
    <div class="plan-wrap">
      <div class="plan-list">
        <div class="is-scroll-x">
          <div
            class="plan-item"
            v-for="(item, index) in basicList"
            :key="index"
          >
            <div class="plan-details">
              <h3>{{item.name}}</h3>
              <div>已落位品牌数量：<span>{{item.locationNum}}</span> </div>
              <div>已落位商铺面积：<span>{{item.locationArea}}㎡</span> </div>
              <div>空 置 商 铺 面积：<span>{{item.emptyArea}}㎡</span> </div>
              <div>连 锁 品 牌 数量：<span>{{item.isliansuo}}</span> </div>
              <div>新 兴 品 牌 数量：<span>{{item.isXxPp}}</span> </div>
              <div>方案年度租金收益：<span>{{item.planYearRentForecast}}万</span> </div>
            </div>
            <img
              src="@/assets/images/workBench/pk.png"
              alt=""
            >
          </div>
        </div>
      </div>
      <div class="area-contrast">
        <i></i>
        <div class="select-wrap">
          <el-select
            @change="changeTile1"
            v-model="titleVal"
            popper-class="w-block-select-down"
          >
            <el-option
              v-for="item in titleList1"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-tooltip
            class="item"
            popper-class="planTooltip"
            effect="dark"
            placement="bottom"
          >
            <em>
              <span class="icon-mark"></span>
            </em>
            <div slot="content">一个铺位多个落位计划品牌时，以首选落位品牌为基准进行分析</div>
          </el-tooltip>
        </div>
        <div class="floor-item type-item">
          <el-select
            v-model="selectType"
            @change="changeType"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ytList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="floor-item">
          <!-- <el-select
            v-model="floorStr"
            @change="changeFloor"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in floorArr"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select> -->
          <el-cascader
            placeholder="请选择楼层"
            popper-class="black-select-panel"
            :options="floorArr"
            v-model="floorStr"
            @change="changeFloor"
            ref="myCascader"
            size="medium"
            filterable
            clearable
          ></el-cascader>
        </div>
      </div>
      <div class="charts-wrap">
        <div
          id="planBar"
          :style="{width:'1280px',height:'330px'}"
        ></div>
      </div>
      <div class="area-contrast">
        <i></i>
        <div class="select-wrap">
          <el-select
            @change="changeTile2"
            v-model="titleVal2"
            popper-class="w-block-select-down"
          >
            <el-option
              v-for="item in titleList2"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-tooltip
            class="item"
            popper-class="planTooltip"
            effect="dark"
            placement="bottom"
          >
            <em>
              <span class="icon-mark"></span>
            </em>
            <div slot="content">一个铺位多个落位计划品牌时，以首选落位品牌为基准进行分析</div>
          </el-tooltip>
        </div>
        <div class="floor-item type-item">
          <el-select
            v-model="selectType2"
            @change="changeType2"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ytList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="floor-item">
          <!-- <el-select
            v-model="floorStr2"
            @change="changeFloor2"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in floorArr"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select> -->
          <el-cascader
            placeholder="请选择楼层"
            popper-class="black-select-panel"
            :options="floorArr"
            v-model="floorStr2"
            @change="changeFloor2"
            ref="myCascader"
            size="medium"
            filterable
            clearable
          ></el-cascader>
        </div>
      </div>
      <div class="charts-wrap">
        <div
          id="planBar2"
          :style="{width:'1280px',height:'330px'}"
        ></div>
      </div>
      <div
        class="area-contrast"
        style="padding-left: 6px;"
      >
        <i style="top:2.5px;left:-5px;"></i>品牌组合对比
      </div>
      <div class="type-wrap">
        <span
          v-for="(item, index) in typeList"
          :key="index"
          :class="[item === typeName ? 'active': '']"
          @click="tabClick(item)"
        >{{item}}</span>
      </div>
      <div
        class="two-type-wrap"
        v-if="twoTypeList && twoTypeList.length > 0"
      >
        <span
          v-for="(item, index) in twoTypeList"
          :key="index"
          :class="[twoTypeName === item ? 'active': '']"
          @click="tabTwoClick(item)"
        >{{item}}</span>
      </div>
      <div class="brand-wrap w-is-scroll">
        <div
          class="brand-item ellipsis"
          v-for="(item, index) in brandList"
          :key="index"
        >
          <div class="title">{{item.planName}}</div>
          <div
            class="pointer"
            @click="toDetails(list)"
            v-for="(list, key) in item.brandNames"
            :key="key"
            :title="list.brandName"
          >{{list.brandName}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  data () {
    return {
      basicList: [],
      floorStr: '全部楼层',
      floorStr2: '全部楼层',
      buildingId: '',
      floorId: '',
      floorId2: '',
      buildingId2: '',
      floorArr: [],
      typeList: [],
      twoTypeList: [],
      typeName: '',
      twoTypeName: '不限',
      brandList: [],
      selectType: '一级业态',
      selectType2: '一级业态',
      selectTypeId: '1',
      selectTypeId2: '1',
      ytList: [{ label: '一级业态', value: '1' }, { label: '二级业态', value: '2' }],
      ytList2: [{ label: '一级业态', value: '1' }, { label: '二级业态', value: '2' }],
      titleVal: '业态面积占比对比',
      titleId1: 1,
      titleList1: [{ label: '业态面积占比对比', value: 1 }, { label: '业态铺位占比对比', value: 2 }],
      titleId2: 1,
      titleVal2: '新兴品牌数量对比',
      titleList2: [{ label: '新兴品牌数量对比', value: '1' }, { label: '连锁品牌数量对比', value: '2' }]
    }
  },
  mounted () {
    this.getContrastType()
    this.getContrastType2()
    this.getContrastMsg()
    this.getTypeFunc()
    this.getFloorFunc()
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  methods: {
    changeTile1 (item) {
      this.titleId1 = item.value
      this.getContrastType()
    },
    changeTile2 (item) {
      this.titleId2 = item.value
      this.getContrastType2()
    },
    changeFloor (val) {
      console.log(val)
      this.buildingId = val[0]
      this.floorId = val[1]
      this.getContrastType()
    },
    changeFloor2 (val) {
      this.buildingId2 = val[0]
      this.floorId2 = val[1]
      this.getContrastType2()
    },
    changeType (val) {
      this.selectTypeId = val
      this.getContrastType()
    },
    changeType2 (val) {
      this.selectTypeId2 = val
      this.getContrastType2()
    },
    getFloorFunc () {
      // this.axios.post(api.planContrastFloor, { projectId: this.$route.query.id })
      //   .then((res) => {
      //     this.floorArr = res.data.data.map((item) => {
      //       const obj = {
      //         label: item
      //       }
      //       return obj
      //     })
      //     const data = {
      //       label: '全部楼层'
      //     }
      //     this.floorArr.unshift(data)
      //   })
      this.axios.post(api.getWholeFloorData, { projectId: this.$route.query.id })
        .then((res) => {
          this.floorArr = res.data.data.map((item) => {
            item.children = item.children.map((option) => {
              const tem = {
                value: option.value,
                label: option.label
              }
              return tem
            })
            return item
          })
        })
    },
    getTypeFunc () {
      this.axios.post(api.getPlanTypeList, { projectId: this.$route.query.id })
        .then((res) => {
          this.typeList = res.data.data
          this.typeName = this.typeList[0]
          this.getTwoTypeFunc(this.typeName)
        })
    },
    getContrastMsg () {
      this.axios.post(api.planContrastBasic, { projectId: this.$route.query.id })
        .then((res) => {
          this.basicList = res.data.data
        })
    },
    getContrastType () {
      this.axios.post(api.planContrastType,
        {
          building: this.buildingId,
          floor: this.floorId,
          projectId: this.$route.query.id,
          type: this.selectTypeId,
          selectType: this.titleId1
        })
        .then((res) => {
          this.setBarData(res.data.data)
        })
    },
    getContrastType2 () {
      this.axios.post(api.planContrastShopNumber,
        {
          building: this.buildingId2,
          floor: this.floorId2,
          projectId: this.$route.query.id,
          type: this.selectTypeId2,
          selectType: this.titleId2
        })
        .then((res) => {
          this.setBarData2(res.data.data)
        })
    },
    tabClick (item) {
      this.typeName = item
      this.twoTypeName = '不限'
      this.getTwoTypeFunc(item)
      this.getBrandInfo()
    },
    tabTwoClick (item) {
      this.twoTypeName = item
      this.getBrandInfo()
    },
    getBrandInfo () {
      this.axios.post(api.getPlanBrandInfo,
        {
          typeName1: this.typeName,
          typeName2: this.twoTypeName,
          projectId: this.$route.query.id
        })
        .then((res) => {
          this.brandList = res.data.data
        })
    },
    getTwoTypeFunc (typeName) {
      this.axios.post(api.getPlanTypeList,
        {
          projectId: this.$route.query.id,
          yetaiName: typeName
        })
        .then((res) => {
          this.twoTypeName = '不限'
          this.twoTypeList = res.data.data
          this.getBrandInfo()
        })
    },
    toDetails (item) {
      // if (item.brandId > 0) {
      //   const brandDetailsHref = this.$router.resolve({
      //     path: '/brandDetail',
      //     query: {
      //       id: item.brandId,
      //       source: 1,
      //       gbid: item.gbid,
      //       questionState: 0
      //     }
      //   })
      //   window.open('/' + brandDetailsHref.href, '_blank')
      // } else {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
      // }
    },
    setBarData (data) {
      const that = this
      const chat = []
      for (let i = 0; i < data.data.length; i++) {
        const obj = {}
        obj.name = data.data[i].name
        obj.barGap = 0.3
        obj.type = 'bar'
        obj.data = data.data[i].percent
        obj.barWidth = '16px'
        chat.push(obj)
      }
      const myChart = echarts.init(document.getElementById('planBar'))
      const option = {
        legend: {
          textStyle: {
            color: '#fff'
          },
          itemWidth: 10,
          itemHeight: 10,
          icon: 'square',
          right: 'center',
          bottom: '0px'
        },
        tooltip: {
          formatter (params) {
            for (const i in data.data) {
              if (params.seriesName === data.data[i].name) {
                if (that.titleId1 === 1) {
                  return params.seriesName + '<br/>' + params.name + '<br/>' + '面积：' + data.data[i].area[params.dataIndex] + '㎡' + '<br/>' + '占比：' + data.data[i].percent[params.dataIndex] + '%'
                } else {
                  return params.seriesName + '<br/>' + params.name + '<br/>' + '铺位数量：' + data.data[i].area[params.dataIndex] + '<br/>' + '占比：' + data.data[i].percent[params.dataIndex] + '%'
                }
              }
            }
          }
        },
        color: ['#16DA64', '#26CBE9', '#3494FF', '#F78121'],
        // dataset: {
        //   source: [
        //     ['product', '2015', '2016', '2017'],
        //     ['Matcha Latte', 43.3, 85.8, 93.7],
        //     ['Milk Tea', 83.1, 73.4, 55.1],
        //     ['Cheese Cocoa', 86.4, 65.2, 82.5],
        //     ['Walnut Brownie', 72.4, 53.9, 39.1]
        //   ]
        // },
        xAxis: [
          {
            type: 'category',
            data: data.yetais,
            axisLabel: {
              rotate: 0
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            }
          }
        ],
        yAxis: [
          {
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            },
            axisLabel: {
              show: true,
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }
        ],
        series: chat
      }
      myChart.setOption(option)
    },
    setBarData2 (data) {
      const that = this
      const chat = []
      for (let i = 0; i < data.data.length; i++) {
        const obj = {}
        obj.name = data.data[i].name
        obj.barGap = 0.3
        obj.type = 'bar'
        obj.data = data.data[i].area
        obj.barWidth = '16px'
        chat.push(obj)
      }
      const myChart = echarts.init(document.getElementById('planBar2'))
      const option = {
        legend: {
          textStyle: {
            color: '#fff'
          },
          itemWidth: 10,
          itemHeight: 10,
          icon: 'square',
          right: 'center',
          bottom: '0px'
        },
        tooltip: {
          formatter (params) {
            for (const i in data.data) {
              if (params.seriesName === data.data[i].name) {
                return params.seriesName + '<br/>' + params.name + '<br/>' + (that.titleId2 === 1 ? '新兴品牌数量' : '连锁品牌数量') + '：' + data.data[i].area[params.dataIndex] + '<br/>' + '占比：' + data.data[i].percent[params.dataIndex] + '%'
              }
            }
          }
        },
        color: ['#16DA64', '#26CBE9', '#3494FF', '#F78121'],
        // dataset: {
        //   source: [
        //     ['product', '2015', '2016', '2017'],
        //     ['Matcha Latte', 43.3, 85.8, 93.7],
        //     ['Milk Tea', 83.1, 73.4, 55.1],
        //     ['Cheese Cocoa', 86.4, 65.2, 82.5],
        //     ['Walnut Brownie', 72.4, 53.9, 39.1]
        //   ]
        // },
        xAxis: [
          {
            type: 'category',
            data: data.yetais,
            axisLabel: {
              rotate: 0
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            }
          }
        ],
        yAxis: [
          {
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.5)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }
        ],
        series: chat
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="stylus" scoped>
.plan-wrap
  width 1280px
  min-height 600px
  margin 0px auto
  position relative
  background #272930
  padding 24px 16px
  box-sizing border-box
  .plan-list
    border 1px solid #FFA134
    box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
    padding 30px 45px 0px 36px
    justify-content center
    .is-scroll-x
      padding-bottom 60px
      overflow-x auto
      display flex
      .plan-item
        display flex
        min-width 380px
        // float left
        img
          width 98px
          height 76px
          margin-left 50px
          margin-top 40px
          margin-right 50px
        .plan-details
          h3
            font-weight 500
            color #fff
            font-size 22px
            margin-bottom 18px
          &>div
            color #999
            font-size 14px
            line-height 21px
            span
              color #ffffff
        &:last-child img
          display none
.area-contrast
  position relative
  margin-top 40px
  font-size 18px
  color #ffffff
  // border-left 2px solid #ffa134
  padding-left 10px
  margin-left 20px
  height 30px
  .select-wrap
    position absolute
    left 0
    top 3px
    input
      border-color #ffa134
      background none
  .floor-item
    position absolute
    right 0
    top 0
    input
      border-color #ffa134
  .type-item
    position absolute
    right 160px
    top 0
    input
      border-color #ffa134
  i
    position absolute
    left -10px
    top 14px
    width 2px
    height 18px
    background #FFA134
.charts-wrap
  margin-top 30px
  border-top 1px solid rgba(226, 226, 226, 0.1)
  #planBar
    margin auto
    canvas
      height 320px
.type-wrap
  margin-left 30px
  margin-top 36px
  span
    display inline-block
    width 138px
    height 34px
    line-height 34px
    text-align center
    border-radius 3px
    border 1px solid rgba(255, 255, 255, 0.5)
    color #fff
    font-size 16px
    margin-right 16px
    cursor pointer
    &.active
      color #ffa134
      border-color #ffa134
.two-type-wrap
  background rgba(30, 30, 35, 0.5)
  height 45px
  line-height 45px
  color #fff
  margin-top 12px
  margin-left 30px
  padding-left 20px
  span
    margin-right 30px
    cursor pointer
    font-weight 500
    &.active
      color #ffa134
.brand-wrap
  display flex
  overflow-x scroll
  padding-bottom 40px
  .brand-item
    min-width 200px
    background rgba(226, 226, 226, 0.1)
    margin 0 30px
    color #fff
    box-sizing border-box
    margin-top 50px
    font-size 14px
    .title
      color rgba(255, 255, 255, 0.5)
    &>div
      height 44px
      line-height 44px
      padding-left 16px
      border-bottom 1px solid rgba(226, 226, 226, 0.1)
</style>
