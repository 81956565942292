<template>
  <div class="w-contrast-index">
    <div class="w-crumbs-href">
      <router-link tag="span" :to="{ path: '/' }">工作台</router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link tag="span" :to="{ path: '/work' }">招商规划管理</router-link>
      <i class="el-icon-arrow-right"></i>
      <span class="w-white">{{currentTab === 'plan' ? '落位方案对比': '竞品项目对比'}}</span>
    </div>
    <div class="contrast-wrap">
      <span @click="tabClick('project')" :class="[currentTab === 'project' ? 'active': '']">竞品项目对比</span>
      <span @click="tabClick('plan')" :class="[currentTab === 'plan' ? 'active': '']">落位方案对比</span>
    </div>
    <div>
      <router-view
        :is="currentTab"
        keep-alive></router-view>
    </div>
  </div>
</template>

<script>
import plan from './contrastPlan'
import project from './contrastProject'
export default {
  data () {
    return {
      currentTab: 'project'
    }
  },
  components: {
    plan,
    project
  },
  methods: {
    tabClick (type) {
      this.currentTab = type
    },
    toPlanProject () {
      this.$router.push({
        path: '/planningProject'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-contrast-index
  width 1280px
  margin auto
.contrast-wrap
  width 1280px
  margin 0px auto
  position relative
  span
    display inline-block
    width 194px
    height 40px
    line-height 40px
    color rgba(255,255,255,1)
    background #23252C
    font-size 18px
    text-align center
    border-radius 2px 2px 0px 0px
    margin-right 6px
    cursor pointer
    &.active
      background #272930
      color #FFA134
.crumbs
  width 1280px
  margin auto
  color #ccc
  font-size 14px
  margin-bottom 20px
  span
    color #fff
    cursor pointer
</style>
